import React, { useEffect, useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import store from "../../store";

export const DataGridTool = (props) => {
    const [selectedRows, setSelectedRows] = useState(props.rows);
    const consultant_isKM = store.getState().user.consultant.isKM;

    useEffect(() => {
        setSelectedRows(props.rows);
    }
    , [props.rows]);
    
    const columns = [
        {
          field: "name",
          headerName: "Nombre Herramienta",
          flex: 10,
        },
        {
          field: "contact",
          headerName: "Contacto",
          flex: 10,
        },
        {
          field: "link",
          headerName: "Link",
          flex: 10,
          renderCell: (params) => (
            <a href={`https://${params.value}`} target="_blank" rel="noopener noreferrer">
              {params.value}
            </a>
          ),
        },
        {
          field: "training_link",
          headerName: "Link Capacitación",
          flex: 10,
          renderCell: (params) => (
            <a href={`https://${params.value}`} target="_blank" rel="noopener noreferrer">
              {params.value}
            </a>
          ),
        },
        {
          field: "desc",
          headerName: "Descripción",
          flex: 10,
        },
        ...(consultant_isKM
          ? [{
              field: 'edit',
              headerName: 'Editar',
              width: 150,
              renderCell: (params) => (
                <Link to={`/modulo_km/backend_edit_tool/${params.row.id}`}>
                  <Button variant="contained" size="small">Editar</Button>
                </Link>
              ),
            }]
          : []
        ),
      ];      

    return (
        <Grid container direction="column" sx={{ mt: 2, height: 500 }}>
            {/* Posibles Filtros */}

            {/* Tabla de Datos */}
            <DataGrid
                rows={selectedRows}
                columns={columns}
                pagesize={10}
                rowsPerPageOptions={[10]}
                disableRowSelectionOnClick
                density="compact"
                sx={{ width: "100%" }}>
            </DataGrid>
        </Grid>
    );
};

export default DataGridTool;
